import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Form, Field } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import Button from '@mui/material/Button';
import CustomScrollbars from 'util/CustomScrollbars';
import renderTextField from 'components/textBox';
import RenderSelectField from 'components/RenderSelectField';
import axios from 'constants/axios';
import { required, emailField } from 'constants/validations';

const ReqDelete = (props) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showCheckError, setShowCheckError] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setShowCheckError(false); // Hide error when checkbox is checked
  };

  const onChangeHandle = async (data) => {
    if (!isChecked) {
      setShowCheckError(true);
      return;
    }

    let postData = {
      email: (data.email).toLowerCase(),
      password: data.password,
      user_state: data.user_state  // Sending Buyer/Seller value
    };

    try {
      const result = await axios.post('/users/reqDelete', postData, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      });

      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
        if (localStorage.getItem('buyer_token')) {
          localStorage.removeItem('buyer_token');
        }
        setTimeout(() => {
          navigate('/');
        }, 1000);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong!");
    }
  };

  return (
    <CustomScrollbars>
      <div className="col-xl-8 col-lg-8 signinContainer" style={{ marginTop: "150px" }}>
        <div className="jr-card signinCard mt-5">
          <React.Fragment>
            <div className="login-header mb-0">
              <div className="app-logo">
                <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
              </div>
            </div>
            <Form
              onSubmit={onChangeHandle}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Row>
                    <Col sm={12} className="text-left mb-2">
                      <Field
                        name="user_state"
                        component={RenderSelectField}
                        label="Account Type"
                        validate={required}
                        fullWidth
                      >
                        <option value="" />
                        <option value="Buyer">Buyer</option>
                        <option value="Seller">Seller</option>
                      </Field>
                    </Col>
                    <Col sm={12} className="text-left">
                      <Field name="email" component={renderTextField} label="Email" validate={emailField} />
                    </Col>
                    <Col sm={12} className="text-left">
                      <Field type="password" name="password" component={renderTextField} label="Password" validate={required} />
                    </Col>
                    <Col sm={12} className="text-left">
                      <input type="checkbox" id="agree" checked={isChecked} onChange={handleCheckboxChange} />
                      &nbsp;
                      <span>I acknowledge that all my data will be deleted and that this action is irreversible.</span>
                      {showCheckError && <div style={{ color: 'red', fontSize: '14px' }}>You must check the box to proceed.</div>}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} className="text-left">
                      <div className='list-action mt-3'>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="medium"
                        >
                          Request Delete
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </React.Fragment>
        </div>
      </div>
    </CustomScrollbars>
  );
};

export default ReqDelete;
