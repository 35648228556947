import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { NotificationContainer } from 'react-notifications';
import configureStore from './store';
import "assets/vendors/style"
import './firebase-conf';
import './Seller.css';
import './App.css';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import "react-datepicker/dist/react-datepicker.css";
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SignIn from 'containers/SignIn';
import SignUp from 'containers/SignUp';

import ReqDelete from 'containers/reqDelete';

import BuyerSignUp from 'containers/BuyerSignUp';
import AdminSignIn from 'containers/AdminSignIn';
import ForgotPassword from 'containers/forgotPassword';
import VerifyToken from 'containers/verifyToken';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import indigoTheme from 'containers/themes/indigoTheme';
import BuyerDashboard from 'components/buyerDashboard';
import SearchResult from "components/bSearchResults";
import ProductDetails from "components/bProductDetails";
import ViewSeller from "components/bSeller"
import ViewCompanyFront from "components/bCompanyFront"
import AboutUs from 'components/Footer/pages/AboutUs';
import ContactUs from 'components/Footer/pages/ContactUs';
import TermsOfUse from 'components/Footer/pages/TermsOfUse';
import Privacy from 'components/Footer/pages/Privacy';
import ShippingAndReturnPolicy from 'components/Footer/pages/ShippingAndReturnPolicy';
import MyCart from 'components/myCart';
import ShortBook from 'components/BShortBook'
import Profile from 'components/bProfile'
import Dashboard from 'components/Dashboard'
import Orders from 'components/orders';
import TrackOrder from 'components/trackOrder';
import OrderDetails from 'components/orderDetails';
import BulkRequests from 'components/BulkRequests';
import Tickets from 'components/tickets';
import TicketDetails from 'components/ticketDetails';
import Inventories from 'components/inventory';
import Settlement from 'components/settlement';
import ProductRequest from 'components/productRequest';
import Staff from 'components/staff';
import Groups from 'components/groups';
import ComplainceForm from 'components/complainceForm';
import ListNotification from 'components/listNotification';
import BuyerNotification from 'components/BuyerNotification';
import ChangePassword from 'components/changePassword';
import EditProfile from 'components/editProfile';
import OrderSuccessful from 'components/myCart/OrderSuccessful'

export const store = configureStore();

const MainApp = () => {
  let applyTheme = createTheme(indigoTheme);

  return <Provider store={store}>
    <NotificationContainer />
    <LocalizationProvider dateAdapter={AdapterDayjs}  >
      <ThemeProvider theme={applyTheme}>
        <BrowserRouter>
          <Routes>
            <Route path='/adminLogin' element={<AdminSignIn />} />
            <Route path='/login' element={<SignIn />} />
            <Route path='/buyersignup' element={<BuyerSignUp />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/reqDelete' element={<ReqDelete />} />
            <Route path='/verifyToken' element={<VerifyToken />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path={`/search-results/:keyword/:search`} element={<SearchResult />} />
            <Route path='/product-details/:keyword/:id' element={<ProductDetails />} />
            <Route path={`/view-seller/:id`} element={<ViewSeller />} />
            <Route path={`/view-company/:id`} element={<ViewCompanyFront />} />
            <Route path={`/about-us`} element={<AboutUs />} />
            <Route path={`/ContactUs`} element={<ContactUs />} />
            <Route path={`/TermsOfUse`} element={<TermsOfUse />} />
            <Route path={`/Privacy`} element={<Privacy />} />
            <Route path={`/ShippingAndReturnPolicy`} element={<ShippingAndReturnPolicy />} />
            <Route path={`/myCart`} element={<MyCart />} />
            <Route path={`/shortbook`} element={<ShortBook />} />
            {!localStorage.getItem('asAdmin') ? <Route path={`/profile`} element={<Profile />} /> : ''}
            <Route path={`/seller/dashboard`} element={<Dashboard />} />
            <Route path={`/seller/orders`} element={<Orders />} />
            <Route path={`/seller/orders/trackOrder/:id`} element={<TrackOrder />} />
            <Route path={`/seller/orders/orderDetails/:id`} element={<OrderDetails />} />
            <Route path={`/seller/bulkrequests`} element={<BulkRequests />} />
            <Route path={`/seller/tickets`} element={<Tickets />} />
            <Route path={`/seller/tickets/ticketDetails/:id`} element={<TicketDetails />} />
            <Route path={`/seller/inventory`} element={<Inventories />} />
            <Route path={`/seller/settlement`} element={<Settlement />} />
            <Route path={`/seller/product-Request`} element={<ProductRequest />} />
            <Route path={`/seller/staffs`} element={<Staff />} />
            <Route path={`/seller/groups`} element={<Groups />} />
            <Route path={`/seller/complaince-form`} element={<ComplainceForm />} />
            <Route path={`/seller/list-notification`} element={<ListNotification />} />
            <Route path={`/list-notification`} element={<BuyerNotification />} />
            <Route path={`/seller/change-Password`} element={<ChangePassword />} />
            <Route path={`/seller/edit-Profile`} element={<EditProfile />} />
            <Route path='/orderStatus' element={<OrderSuccessful />} />
            <Route path='/' element={<BuyerDashboard />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>
}


export default MainApp;